@font-face {
    font-family: 'RalewayHeavy';
    src: local('RalewayHeavy'), url("../src/fonts/AC-RalewayHeavy.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewayRegular';
    src: local('RalewayRegular'), url("../src/fonts/AC-RalewayRegular.ttf") format('truetype');
}

@font-face {
    font-family: 'RalewaySemiBold';
    src: local('RalewaySemiBold'), url("../src/fonts/AC-RalewaySemiBold.ttf") format('truetype');
}


.swal2-container {
    z-index: 9999;
  }

  body .swal2-container {
    z-index: 9999 !important;
  }


._flex_row {
    display: flex;
    direction: row;
    align-items: center;
}

._flex_row_space {
    display: flex;
    direction: row;
    justify-content: space-between;
    align-items: center;
}

._flex_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.svg-container {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 15px;
    height: 15px;
    overflow: hidden;
  }